.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-content {
  /* background-color: white !important; */
  padding: 10px;
}

.header-content h1 {
  font-size: 32px;
  color: #dbdbdb;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.sider-bar {
  padding: 20px;
}

.sider-bar h2 {
  margin-bottom: 0;
  color: #3f3f3f;
}

.list-item a {
  width: 100%;
}

.content-page {
  background-color: #fafafa;
  position: relative;
}

.conversation-content {
  padding: 20px;
  background-color: #eeeded;
  min-height: 100vh;
}

.input-content {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 10px;
  background-color: white;
}

.form-input .form-row {
  margin-bottom: 10;
}

.ant-image {
  margin: 10px;
}

.ant-image .img-card {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  object-fit: cover;
}

.icon-button {
  font-size: 20px;
}

.icon-info-profile p {
  padding-right: 20px;
  font-size: 16px;
}

.newpost-container .btn-newpost-content {
  display: none;
}

.newpost-container:hover .btn-newpost-content {
  display: block;
  transition: 3s;
}

.btn-del-img {
  display: 'none';
}

.img-containt:hover .btn-del-img {
  display: 'block';
}

.card-login {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

body {
  min-width: 468px;
  overflow: auto;
}

.card-description .ant-card-meta-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.imgCasuel {
  height: 250px;
  object-fit: cover;
}
.picture-container .ant-upload-list-picture-card-container {
  width: 200px;
  height: 200px;
}

.picture-container
  .ant-upload-list-picture-card-container
  .ant-upload-list-item {
  border: none;
}
